import fetch from 'isomorphic-unfetch'
import { parseCookies } from 'nookies'

const Requester = async (url, { method, headers, body }) => {
    const res = await fetch(url, {
        method: method,
        headers: headers,
        body: body,
    })
    const data = await res.json()
    return data
}

Requester.get = (server_call, ctx, path) => {
    const cookies = parseCookies(ctx)
    var headers = {
        'Content-Type': 'application/json',
    }
    if (cookies.cesto_token_2 !== undefined) {
        headers = {
            Authorization: 'Bearer ' + cookies.cesto_token_2,
            'Content-Type': 'application/json',
        }
    }
    var url = process.env.BROWSER_API + path
    if (server_call) {
        url = process.env.SERVER_API + path
    }
    return Requester(url, {
        method: 'GET',
        headers: headers,
        body: null,
    })
}

Requester.post = (server_call, ctx, path, data = null) => {
    const cookies = parseCookies(ctx)
    var headers = {
        'Content-Type': 'application/json',
    }
    if (cookies.cesto_token_2 !== undefined) {
        headers = {
            Authorization: 'Bearer ' + cookies.cesto_token_2,
            'Content-Type': 'application/json',
        }
    }
    var url = process.env.BROWSER_API + path
    if (server_call) {
        url = process.env.SERVER_API + path
    }
    return Requester(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
    })
}

Requester.put = (server_call, ctx, path, data = null) => {
    const cookies = parseCookies(ctx)
    var headers = {
        'Content-Type': 'application/json',
    }
    if (cookies.cesto_token_2 !== undefined) {
        headers = {
            Authorization: 'Bearer ' + cookies.cesto_token_2,
            'Content-Type': 'application/json',
        }
    }
    var url = process.env.BROWSER_API + path
    if (server_call) {
        url = process.env.SERVER_API + path
    }
    return Requester(url, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(data),
    })
}

Requester.delete = (server_call, ctx, path, data = null) => {
    const cookies = parseCookies(ctx)
    var headers = {
        'Content-Type': 'application/json',
    }
    if (cookies.cesto_token_2 !== undefined) {
        headers = {
            Authorization: 'Bearer ' + cookies.cesto_token_2,
            'Content-Type': 'application/json',
        }
    }
    var url = process.env.BROWSER_API + path
    if (server_call) {
        url = process.env.SERVER_API + path
    }
    return Requester(url, {
        method: 'DELETE',
        headers: headers,
        body: JSON.stringify(data),
    })
}

Requester.upload = (server_call, ctx, path, data = null, headers = {}) => {
    const cookies = parseCookies(ctx)
    if (cookies.cesto_token_2 !== undefined) {
        headers = {
            Authorization: 'Bearer ' + cookies.cesto_token_2,
            ...headers,
        }
    }
    var url = process.env.BROWSER_API + path
    if (server_call) {
        url = process.env.SERVER_API + path
    }
    return Requester(url, {
        method: 'POST',
        headers: headers,
        body: data,
    })
}

export default Requester
